
















import {Component, Vue} from "vue-property-decorator";
import VueMarkdown from "vue-markdown";

@Component({
    props: {
        website: Object,
        contactPresentation: String,
        width: Number,
    },
    components: {
        VueMarkdown,
    },
    computed: {
        cssProps() {
            let headingFontSize: Number;
            let titlePaddingSize: Number;
            let textPaddingSize: Number;
            if (this.$props.width < 1000) {
                headingFontSize = 16;
                titlePaddingSize = 8;
                textPaddingSize = 6;
            } else if (this.$props.width < 1600) {
                headingFontSize = 20;
                titlePaddingSize = 12;
                textPaddingSize = 12;
            } else {
                headingFontSize = 24;
                titlePaddingSize = 24;
                textPaddingSize = 16;
            }

            return {
                "--who-color": this.$props.website != null ? this.$props.website.themeColor1 : "white",
                "--who-title-color": this.$props.website != null ? this.$props.website.themeTitleColor1 : "white",
                "--who-text-color": this.$props.website != null ? this.$props.website.themeTextColor1 : "white",
                "--who-title-align": this.$props.website != null ? this.$props.website.themeTitleAlign1 : "center",
                "--who-heading-font-size": headingFontSize.toString() + "px",
                "--who-title-padding-size": titlePaddingSize.toString() + "px",
                "--who-text-padding-size": textPaddingSize.toString() + "px",
            };
        },
    }
})
export default class WhoCard extends Vue {
}
