import { render, staticRenderFns } from "./ContactCard.vue?vue&type=template&id=3164e2d4&scoped=true&"
import script from "./ContactCard.vue?vue&type=script&lang=ts&"
export * from "./ContactCard.vue?vue&type=script&lang=ts&"
import style0 from "./ContactCard.vue?vue&type=style&index=0&id=3164e2d4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3164e2d4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardTitle } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VList } from 'vuetify/lib'
import { VListTile } from 'vuetify/lib'
import { VListTileAction } from 'vuetify/lib'
import { VListTileContent } from 'vuetify/lib'
import { VListTileTitle } from 'vuetify/lib'
installComponents(component, {
  VCardTitle,
  VIcon,
  VList,
  VListTile,
  VListTileAction,
  VListTileContent,
  VListTileTitle,
})
