


































import {Component, Vue} from "vue-property-decorator";
import WhoCard from "@/components/cards/WhoCard.vue";
import ContactCard from "@/components/cards/ContactCard.vue";

@Component({
    props: {
        website: Object,
        error: Boolean,
        height: Number,
        width: Number,
    },
    components: {
        WhoCard,
        ContactCard,
    },
    computed: {
        cardHeight() {
            if (this.$props.width < 1300) {
                return this.$props.height / 2.2;
            } else {
                return this.$props.height / 2.5;
            }
        },
        adaptedContent() {
            let preciseHeight: Number = this.$props.height / 2.2;
            if (preciseHeight < 350) {
                return this.$props.website.contactSummary;
            } else {
                return this.$props.website.contactPresentation;
            }
        },
        cssProps() {
            return {
                "--background-color": this.$props.theme != null ? this.$props.theme.backgroundColor : "white",
                "--border-color": this.$props.theme != null ? this.$props.theme.borderColor : "grey",
            };
        },
    }
})
export default class Contact extends Vue {
}
