















import {Component, Vue} from "vue-property-decorator";
import ThemeCard from "@/components/cards/ThemeCard.vue";

@Component({
    props: {
        website: Object,
        theme: Object,
        error: Boolean,
        height: Number,
        width: Number,
    },
    components: {
        ThemeCard,
    },
    computed: {
        cardHeight() {
            return this.$props.height / 3;
        },
        adaptedContent() {
            let preciseHeight: Number = this.$props.height / 3;
            if (preciseHeight < 240 || this.$props.width < 780) {
                return null;
            } else if (preciseHeight < 300) {
                return this.$props.theme.summary;
            } else {
                return this.$props.theme.content;
            }
        },
        adaptedSubtitle() {
            let width: Number = this.$props.width;
            if (width < 1400) {
                return this.$props.theme.teasing.alt;
            } else {
                return this.$props.theme.subtitle;
            }
        },
        cssProps() {
            return {
                "--background-color": this.$props.theme != null ? this.$props.theme.backgroundColor : "white",
                "--border-color": this.$props.theme != null ? this.$props.theme.borderColor : "grey",
            };
        },
    }
})
export default class Theme extends Vue {
}
