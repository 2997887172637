
















import {Component, Vue} from "vue-property-decorator";
import VueMarkdown from "vue-markdown";

@Component({
    props: {
        website: Object,
        glyphIcon: String,
        title: String,
        subtitle: String,
        content: String,
        color: String,
        teasing: String,
        width: Number,
    },
    components: {
        VueMarkdown,
    },
    computed: {
        cssProps() {
            let headingFontSize: Number;
            let subheadingFontSize: Number;
            let titlePaddingSize: Number;
            let textPaddingSize: Number;
            if (this.$props.width < 1000) {
                headingFontSize = 16;
                subheadingFontSize = 12;
                titlePaddingSize = 8;
                textPaddingSize = 6;
            } else if (this.$props.width < 1760) {
                headingFontSize = 20;
                subheadingFontSize = 14;
                titlePaddingSize = 12;
                textPaddingSize = 12;
            } else {
                headingFontSize = 24;
                subheadingFontSize = 16;
                titlePaddingSize = 24;
                textPaddingSize = 16;
            }

            return {
                "--theme-color": this.$props.website != null ? this.$props.website["themeColor" + this.$props.color] : "white",
                "--theme-title-color": this.$props.website != null ? this.$props.website["themeTitleColor" + this.$props.color] : "white",
                "--theme-subtitle-color": this.$props.website != null ? this.$props.website["themeSubtitleColor" + this.$props.color] : "white",
                "--theme-text-color": this.$props.website != null ? this.$props.website["themeTextColor" + this.$props.color] : "white",
                "--theme-title-align": this.$props.website != null ? this.$props.website["themeTitleAlign" + this.$props.color] : "center",
                "--theme-subtitle-align": this.$props.website != null ? this.$props.website["themeSubtitleAlign" + this.$props.color] : "center",
                "--theme-text-align": this.$props.website != null ? this.$props.website["themeTextAlign" + this.$props.color] : "justify",
                "--subheading-font-size": subheadingFontSize.toString() + "px",
                "--heading-font-size": headingFontSize.toString() + "px",
                "--title-padding-size": titlePaddingSize.toString() + "px",
                "--text-padding-size": textPaddingSize.toString() + "px",
            };
        },
    }
})
export default class ThemeCard extends Vue {
}
