









































import {Component, Vue} from "vue-property-decorator";

@Component({
    props: {
        website: Object,
        contactEmail: String,
        contactPhone: String,
        contactLocationTitle: String,
        coordinates: Object,
        width: Number,
    },
    computed: {
        cssProps() {
            let headingFontSize: Number;
            let titlePaddingSize: Number;
            let textPaddingSize: Number;
            if (this.$props.width < 1000) {
                headingFontSize = 16;
                titlePaddingSize = 8;
                textPaddingSize = 6;
            } else if (this.$props.width < 1600) {
                headingFontSize = 20;
                titlePaddingSize = 12;
                textPaddingSize = 12;
            } else {
                headingFontSize = 24;
                titlePaddingSize = 24;
                textPaddingSize = 16;
            }

            return {
                "--contact-color": this.$props.website != null ? this.$props.website.themeColor2 : "white",
                "--contact-title-color": this.$props.website != null ? this.$props.website.themeTitleColor2 : "white",
                "--contact-text-color": this.$props.website != null ? this.$props.website.themeTextColor2 : "white",
                "--contact-title-align": this.$props.website != null ? this.$props.website.themeTitleAlign2 : "center",
                "--contact-heading-font-size": headingFontSize.toString() + "px",
                "--contact-title-padding-size": titlePaddingSize.toString() + "px",
                "--contact-text-padding-size": textPaddingSize.toString() + "px",
            };
        },
    }
})
export default class ContactCard extends Vue {
}
