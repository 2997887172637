



































































































import {Component, Vue} from "vue-property-decorator";
import moment from "moment";
import {ajax} from "rxjs/ajax";
import {delay, map, pluck, retryWhen, share} from "rxjs/operators";
import {merge, of} from "rxjs";
import Theme from "@/components/Theme.vue";
import Contact from "@/components/Contact.vue";

@Component({
    props: {
        website: Object,
        error: Boolean,
        height: Number,
        width: Number,
    },
    components: {
        Theme,
        Contact,
    },
    subscriptions() {
        const themes$ = ajax({
            url: process.env.VUE_APP_DB_SERVER + "/themes",
            body: {websiteId: "57cd8214dcba0f63c1d118d5", language: "FR"},
            method: "PUT",
            crossDomain: true,
            withCredentials: false,
            responseType: "json",
        }).pipe(
            pluck("response"),
            map(res => (res as any).themes),
            retryWhen(error => error.pipe(
                delay(1000),
            )),
            share(),
        );
        const error$ = merge(
            themes$.pipe(
                map(value => value === null)
            ),
            of(this.$props.error),
        );
        return {
            themes$,
            error$,
        };
    },
    computed: {
        cssProps() {
            let size: Number;
            if (this.$props.width < 1000) {
                size = 10;
            } else if (this.$props.width < 1600) {
                size = 12;
            } else {
                size = 14;
            }

            let fontSize: String = size.toString() + "px";

            return {
                "--title-font": this.$props.website != null ? this.$props.website.titleFont : "Helvetica",
                "--main-font": this.$props.website != null ? this.$props.website.mainFont : "Arial",
                "--teasing-text-color": this.$props.website != null ? this.$props.website.teasingTextColor : "white",
                "--logo-margin-top": this.$props.height / this.$props.width < 0.6 ? this.$props.height * 0.025 + "px" : this.$props.height * 0.08 + "px",
                "--teaser-margin-top": this.$props.height / this.$props.width < 0.6 ? this.$props.height * 0.035 + "px" : this.$props.height * 0.1 + "px",
                "--background-color": this.$props.website != null ? this.$props.website.navbarColor : "#fafafa",
                "--text-color": this.$props.website != null ? this.$props.website.mainTextColor : "#fafafa",
                "--button-background-color": this.$props.website != null ? this.$props.website.themeSubtitleColor1 : "blue",
                "--button-text-color": this.$props.website != null ? this.$props.website.navbarColor : "white",
                "--footer-text-color": this.$props.website != null ? this.$props.website.navbarColor : "white",
                "--footer-background-color": this.$props.website != null ? this.$props.website.themeColor2 : "orange",
                "--font-size": fontSize,
            };
        },
        parallaxHeight() {
            return this.$props.height / this.$props.width < 1.33 ? this.$props.height * 0.45 : this.$props.height;
        },
        titleHeight() {
            return this.$props.height * 0.06;
        },
    },
    filters: {
        formatDate(date: any) {
            if (date) {
                return moment(String(date)).format("DD/MM/YYYY");
            }
        },
    },
})
export default class Home extends Vue {
}
