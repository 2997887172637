import { render, staticRenderFns } from "./Contact.vue?vue&type=template&id=ca385c00&scoped=true&"
import script from "./Contact.vue?vue&type=script&lang=ts&"
export * from "./Contact.vue?vue&type=script&lang=ts&"
import style0 from "./Contact.vue?vue&type=style&index=0&id=ca385c00&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca385c00",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
installComponents(component, {
  VCard,
  VContainer,
  VFlex,
  VLayout,
})
