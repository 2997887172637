import Vue from 'vue';
import App from './App.vue';
import * as Rx from 'rxjs';
import VueRx from 'vue-rx';
import Vuetify from 'vuetify';
import router from './router';
import store from './store';
import './registerServiceWorker';
import 'vuetify/src/stylus/app.styl';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.use(VueRx, Rx);
Vue.use(Vuetify, {
  iconfont: 'mdi',
});
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app', true);
