import { render, staticRenderFns } from "./Theme.vue?vue&type=template&id=5ff72233&scoped=true&"
import script from "./Theme.vue?vue&type=script&lang=ts&"
export * from "./Theme.vue?vue&type=script&lang=ts&"
import style0 from "./Theme.vue?vue&type=style&index=0&id=5ff72233&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ff72233",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
installComponents(component, {
  VCard,
  VFlex,
})
