import { render, staticRenderFns } from "./WhoCard.vue?vue&type=template&id=6b86f2f5&scoped=true&"
import script from "./WhoCard.vue?vue&type=script&lang=ts&"
export * from "./WhoCard.vue?vue&type=script&lang=ts&"
import style0 from "./WhoCard.vue?vue&type=style&index=0&id=6b86f2f5&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b86f2f5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib'
import { VCardTitle } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
installComponents(component, {
  VCardText,
  VCardTitle,
  VIcon,
})
