












import {Component, Vue} from "vue-property-decorator";
import {ajax} from "rxjs/ajax";
import {debounceTime, delay, map, pluck, retryWhen, share} from "rxjs/operators";
import {fromEvent} from "rxjs";
import Navbar from "@/components/Navbar.vue";

@Component({
    components: {
        Navbar,
    },
    data() {
        const width: Number = window.innerWidth;
        const height: Number = window.innerHeight;
        const smallLogoHeight = "64px";
        const navbarHeight = "64px";

        return {
            width,
            height,
            smallLogoHeight,
            navbarHeight,
        };
    },
    mounted() {
        const clientHeight = document.querySelector(".v-toolbar").clientHeight;
        this.$data.smallLogoHeight = clientHeight != null ? clientHeight * 0.95 : this.$data.height * 0.02;
        this.$data.navbarHeight = clientHeight != null ? clientHeight : this.$data.height * 0.02;
    },
    subscriptions() {
        const website$ = ajax({
            url: process.env.VUE_APP_DB_SERVER + "/site",
            body: {websiteId: "57cd8214dcba0f63c1d118d5", language: "FR"},
            method: "PUT",
            crossDomain: true,
            withCredentials: false,
            responseType: "json",
        }).pipe(
            pluck("response"),
            map(res => (res as any).website),
            retryWhen(error => error.pipe(
                delay(1000),
            )),
            share(),
        );
        const resize$ = fromEvent(window, "resize").pipe(
            share(),
        );
        const windowWidth$ = resize$.pipe(
            debounceTime(250),
            map(() => window.innerWidth),
            share(),
        );
        const windowHeight$ = resize$.pipe(
            debounceTime(250),
            map(() => window.innerHeight),
            share(),
        );
        const error$ = website$.pipe(
            map(value => value === null)
        );

        return {
            website$,
            error$,
            windowWidth$,
            windowHeight$,
        };
    },
    methods: {
        goTo(componentId) {
            console.log(componentId);
            let top: Number = document.getElementById(componentId).offsetTop;
            console.log(top);
            window.scrollTo(0, top + this.$data.navbarHeight);
        }
    }
})
export default class App extends Vue {
}
