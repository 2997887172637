


















import {Component, Vue} from "vue-property-decorator";


@Component({
    props: {
        website: Object,
        error: Boolean,
    },
    data() {
        const height: Number = window.innerHeight;
        const smallLogoHeight = "60px";

        return {
            height,
            smallLogoHeight,
        };
    },
    mounted() {
        const clientHeight = document.querySelector(".v-toolbar").clientHeight;
        this.$data.smallLogoHeight = clientHeight != null ? clientHeight * 0.9 + "px" : this.$data.height * 0.02 + "px";
    },
    computed: {
        cssProps() {
            return {
                "--title-font": this.$props.website != null ? this.$props.website.titleFont : "Helvetica",
                "--background-color": this.$props.website != null ? this.$props.website.navbarColor : "#fafafa",
                "--text-color": this.$props.website != null ? this.$props.website.mainTextColor : "#fafafa",
                "--navbar-text-color": this.$props.website != null ? this.$props.website.navbarTextColor : "#fafafa",
                "--navbar-highlight-color": this.$props.website != null ? this.$props.website.themeColor1 : "#fafafa",
            };
        },
    },
})
export default class Navbar extends Vue {
}
